import { IEmployee } from '@/types.ts';
import { getUrl } from '@/utils/api';

function useEmployees() {
  const query = getUrl(`v1/employees`);
  return {
    ...query,
    data: query.data as IEmployee[],
  };
}

export default useEmployees;
