import { useParams } from 'react-router';
import { IAccountUser } from '@/types.ts';
import { getUrl } from '@/utils/api';

function useAccountUsers() {
  const { accountId } = useParams();
  const query = getUrl(`admin/account_users?account_id=${accountId}`);
  return {
    ...query,
    data: query.data as IAccountUser[],
  };
}

export default useAccountUsers;
