import { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import useAccounts from '@/hooks/useAccounts';
import { IAccount } from '@/types';
import DataTable from '../DataTable/DataTable';
import Section from '../Section';
import Time from '../Time';
import { Badge } from '../ui/badge';
import { Button } from '../ui/button';

const Accounts = () => {
  const accounts = useAccounts();
  const data = useMemo(() => accounts.data || [], [accounts.data]);

  const columns: ColumnDef<IAccount>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Account',
        cell: ({ row }) => (
          <Button variant="outline" asChild>
            <Link to={`/admin/users/${row.original.id}`}>{row.original.name}</Link>
          </Button>
        ),
      },
      {
        accessorKey: 'provider_type',
        header: 'Provider Type',
        cell: ({ row }) => <Badge>{row.original.provider_type}</Badge>,
      },
      {
        accessorKey: 'provider_pharmacy_cost',
        header: 'Pharmacy Cost',
        cell: ({ row }) => <>${row.original.provider_pharmacy_cost}</>,
      },
      {
        accessorKey: 'created_at',
        header: 'Invited',
        cell: ({ row }) => <Time>{row.original.created_at}</Time>,
      },
      {
        accessorKey: 'owner.name',
        header: 'Owner',
        cell: ({ row }) => <>{row.original.owner.name}</>,
      },
    ],
    [],
  );

  return (
    <>
      <div className="container px-4 mx-auto">
        <h1 className="mb-4 text-2xl font-bold">Accounts</h1>

        <Section>
          <DataTable data={data} columns={columns} model={accounts} placeholder={'Search patients...'} />
        </Section>
      </div>
    </>
  );
};

export default Accounts;
