import { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import useAccountUsers from '@/hooks/useAccountUsers';
import { IAccountUser } from '@/types';
import DataTable from '../DataTable/DataTable';
import Section from '../Section';
import Time from '../Time';
import { Badge } from '../ui/badge';
import { Button } from '../ui/button';

const AccountUsers = () => {
  const accountUsers = useAccountUsers();
  const data = useMemo(() => accountUsers.data || [], [accountUsers.data]);

  const columns: ColumnDef<IAccountUser>[] = useMemo(
    () => [
      {
        accessorKey: 'user.name',
        header: 'User',
        cell: ({ row }) => (
          <Button variant="outline" asChild>
            <Link to={`/admin/users/${row.original.user.id}`}>{row.original.user.name}</Link>
          </Button>
        ),
      },
      {
        accessorKey: 'user.email',
        header: 'Email',
      },
      {
        accessorKey: 'role',
        header: 'Role',
        cell: ({ row }) => <Badge>{row.original.role}</Badge>,
      },
      {
        accessorKey: 'created_at',
        header: 'Invited',
        cell: ({ row }) => <Time>{row.original.created_at}</Time>,
      },
    ],
    [],
  );

  return (
    <Section>
      <DataTable data={data} columns={columns} model={accountUsers} placeholder={'Search patients...'} />
    </Section>
  );
};

export default AccountUsers;
