import axios from 'axios';

const getToken = (): string | null => {
  const data = localStorage.getItem('Needle');
  if (data) {
    return JSON.parse(data).sessionId;
  }
  return null;
};

const axiosFileUpload = axios.create({
  baseURL: import.meta.env.VITE_NEEDLE_API_URL,
});

axiosFileUpload.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['Needle-Admin-Token'] = token;
    }
    // Important: Do not set Content-Type here, let it be set automatically by the browser for multipart/form-data
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosFileUpload.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle 401 errors
    if (error.response && error.response.status === 401) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);

export default axiosFileUpload;
