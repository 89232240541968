import { Input } from '../ui/input';

const DataTableFilter = ({
  globalFilter,
  setGlobalFilter,
  placeholder = 'Search...',
}: {
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
  placeholder: string;
}) => {
  return (
    <Input
      value={globalFilter || ''}
      onChange={(e) => setGlobalFilter(e.target.value)}
      placeholder={placeholder}
      className="mb-4"
    />
  );
};

export default DataTableFilter;
