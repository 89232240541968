import { useState, useRef } from 'react';
import { SendIcon } from 'lucide-react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import useTextGroups from '@/hooks/useTextGroups';
import useTextMessages from '@/hooks/useTextMessages';
import axiosClient from '@/utils/axiosClient';
import { cn } from '@/utils/utils';
import Loading from '../Loading';
import Section from '../Section';
import Time from '../Time';
import { Button } from '../ui/button';
import { Input } from '../ui/input';

const TextMessaging = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const text_groups = useTextGroups();
  const text_messages = useTextMessages(userId || '');

  const [newMessage, setNewMessage] = useState('');

  const handleGroupSelection = (user_id: string) => {
    navigate(`/messages/${user_id}`);
  };

  const handleNewMessage = () => {
    if (newMessage) {
      axiosClient
        .post('v2/text_messages', {
          body: newMessage,
          user_id: userId,
        })
        .then(() => {
          queryClient.invalidateQueries('v2/text_messages');
          queryClient.invalidateQueries('v2/text_messages/' + userId);
        });

      setNewMessage('');
    }
  };

  const selectedGroup = text_groups.data?.find((group) => group.user_id === userId);

  const messagesEndRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   if (messagesEndRef.current) {
  //     messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [text_messages.data]);

  return (
    <div className="flex flex-col gap-8 sm:flex-row">
      {/* Left Side */}
      <div className="flex flex-col flex-none h-full gap-2 overflow-hidden sm:w-96 ">
        <Section title="Latest Text Messages" className="flex-1 overflow-y-auto">
          <div className="border-t border-t-gray-300">
            {text_groups.isLoading ? (
              <Loading />
            ) : (
              text_groups.data?.map((textGroup) => {
                const latestMessage = textGroup.text_messages.sort((a, b) => {
                  return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                })[0];

                return (
                  <div
                    key={textGroup.user_id}
                    onClick={() => handleGroupSelection(textGroup.user_id)}
                    className={cn(
                      'flex flex-col p-2 cursor-pointer border-b border-b-gray-300',
                      userId === textGroup.user_id && 'bg-purple-200',
                    )}
                  >
                    <div className="font-semibold whitespace-nowrap">
                      {textGroup.user.name ? textGroup.user.name : textGroup.user.email}
                    </div>
                    <div className="whitespace-nowrap">
                      <Time className="mt-1 text-xs">{latestMessage.created_at}</Time>
                      <div className="mt-2 text-xs truncate">{latestMessage.body}</div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </Section>
      </div>

      <div className="flex flex-col flex-1 h-full ">
        <Section
          className="flex flex-col h-full"
          title={
            selectedGroup && `Text Messages for ${[selectedGroup.user.name, selectedGroup.user.email].join(' | ')}`
          }
          rightSide={
            selectedGroup && (
              <Button asChild>
                <Link to={`/admin/users/${selectedGroup.user.id}`}>Profile</Link>
              </Button>
            )
          }
        >
          {selectedGroup ? (
            <div className="flex flex-col h-full">
              {/* Messages Container */}
              <div className="flex-1 p-4">
                {text_messages.isLoading ? (
                  <Loading />
                ) : (
                  <div className="flex flex-col gap-4">
                    {text_messages.data?.map((message) => (
                      <div
                        key={message.id}
                        className={cn(
                          'flex',
                          message.direction === 'inbound' ? 'justify-end text-right' : 'justify-start',
                        )}
                      >
                        <div className="max-w-xs p-2">
                          <div
                            className={cn(
                              'px-4 py-2 text-sm rounded-lg',
                              message.direction === 'inbound'
                                ? 'bg-blue-200 text-right rounded-br-none'
                                : 'bg-purple-200 rounded-bl-none',
                            )}
                          >
                            {message.body}
                          </div>
                          <Time className="mt-1 text-xs text-gray-500">{message.created_at}</Time>
                          {message.direction === 'outbound' && (
                            <div className="text-xs text-gray-500">
                              {message.author ? message.author?.name : 'Needle'}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                    <div ref={messagesEndRef} />
                  </div>
                )}
              </div>

              {/* Input Bar */}
              <div className="p-4 border-t border-gray-200">
                <div className="flex items-center">
                  <Input
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type a short text message..."
                    className="flex-1 px-4 py-2 text-sm bg-gray-100 border border-gray-200 rounded-lg focus:outline-none"
                  />
                  <Button className="gap-2 ml-2" onClick={handleNewMessage}>
                    <SendIcon size={16} /> Send
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center h-full">Select a user to view messages</div>
          )}
        </Section>
      </div>
    </div>
  );
};

export default TextMessaging;
