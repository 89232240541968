import { useState, useEffect } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { ExternalLink } from 'lucide-react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import ClickToCopy from '@/components/ClickToCopy';
import Location from '@/components/Location/Location';
import MedicationList from '@/components/Medications/List.tsx';
import Section from '@/components/Section';
import Time from '@/components/Time';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import useMe from '@/hooks/useMe';
import { IRequest, ITask } from '@/types';
import axiosClient from '@/utils/axiosClient';
import { formatPhoneNumber } from '@/utils/helpers';
import { PharmaciesTaskTable } from './DataTable/PharmaciesTaskTable';
import { PharmaciesTaskTableColumns } from './DataTable/PharmaciesTaskTableColumns';

interface RequestProps {
  request: IRequest;
}

const Request = ({ request }: RequestProps) => {
  const { requestId, taskId: urlTaskId, tab, callId } = useParams();
  const navigate = useNavigate();
  const taskModal = useModal('task-modal');
  const [selectedTaskId, setSelectedTaskId] = useState<string | undefined>(undefined);
  const { data: currentUser } = useMe();

  useEffect(() => {
    setSelectedTaskId(urlTaskId);
  }, [urlTaskId]);

  const handleRowClick = (task: ITask) => {
    setSelectedTaskId(task.id);
    navigate(`/admin/requests/${requestId}/${task.id}/${task.medication_id}`);
    taskModal.show({ requestId, taskId: task.id, tab, callId });
  };

  const saveNote = (note: string) => {
    if (note === '') {
      return;
    }
    axiosClient
      .put(`/admin/requests/${request.id}`, {
        needle_note: note,
      })
      .then((response) => {
        request = response.data;
      });
  };

  if (!request?.id) {
    return null;
  }

  const handleRequestClosure = (sendEmail: boolean) => {
    if (currentUser) {
      axiosClient
        .put(`/admin/requests/${request.id}/close`, {
          send_email: sendEmail,
        })
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          console.error('Error closing request:', error);
        });
    } else {
      console.error('User information not available');
    }
  };

  return (
    <>
      <div className="mb-4">
        <Section
          title={
            <div className="flex flex-row items-center gap-2">
              <ClickToCopy text={`${requestId}`} className="text-sm font-semibold" />
              <Button className="gap-2" asChild>
                <Link to={`https://app.findneedle.co/requests/${request.id}`} target={'_blank'}>
                  <ExternalLink className="w-4 h-4" />
                  Go to Request
                </Link>
              </Button>
            </div>
          }
          rightSide={
            <div className="flex items-center gap-4">
              <Badge color="blue" className="text-xs">
                {request.processing_time}
              </Badge>
              <Badge color="blue" className="text-xs">
                <Time>{request.created_at as string}</Time>
              </Badge>
            </div>
          }
        >
          <div className="flex flex-col items-start gap-4 text-lg md:flex-row md:text-2xl md:items-center">
            <div className="flex">{request.user.name}</div>
            <div className="flex">{request.user.email}</div>
            <div className="flex">{formatPhoneNumber(request.user.phone as string)}</div>
          </div>
        </Section>
      </div>

      <div className="flex flex-col gap-6 lg:flex-row ">
        <div className="flex-1">
          <MedicationList request={request} />

          <div className="flex-1 mt-8">
            <Section title="Note for Customer">
              <div className="w-full">
                <Textarea
                  className="h-28"
                  placeholder="Customer will see this note"
                  defaultValue={request.needle_note}
                  onBlur={(e) => saveNote(e.target.value)}
                />
              </div>

              {request.status !== 'completed' ? (
                <div className="flex flex-row justify-between gap-2">
                  <Button variant={'inStock'} onClick={() => handleRequestClosure(true)} className="m">
                    Complete Request Send Email
                  </Button>
                  <Button variant={'destructive'} onClick={() => handleRequestClosure(false)} className="m">
                    Complete Request No Email
                  </Button>
                </div>
              ) : (
                <div className="flex flex-row justify-between gap-2"></div>
              )}
            </Section>
          </div>

          <div className="flex-1 mt-8">
            <Section title="Note for Needle from Customer">
              <div className="w-full px-4 mb-2 text-sm whitespace-pre-line">{request.note}</div>
            </Section>
          </div>
        </div>
        <div className="flex-1 w-full sm:flex-row ">
          <Location request={request} />
        </div>
      </div>

      <div className="flex flex-row w-full gap-4 mt-8">
        <div className="flex-1">
          <Section
            title="Tasks"
            rightSide={
              <Badge color="blue" className="text-xs">
                {request.tasks.length} Tasks
              </Badge>
            }
          >
            <div className="flex-1 w-full overflow-x-scroll">
              <PharmaciesTaskTable
                columns={PharmaciesTaskTableColumns as any}
                data={request.tasks as any}
                onRowClick={handleRowClick}
                selectedTaskId={selectedTaskId}
              />
            </div>
          </Section>
        </div>
      </div>
    </>
  );
};

export default Request;
