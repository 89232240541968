import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import Location from '@/components/Location/Location.tsx';
import RequestSummary from '@/components/Requests/RequestSummary.tsx';
import { Textarea } from '@/components/ui/textarea.tsx';
import { IRequest } from '@/types.ts';
import MedicationList from '../Medications/List.tsx';
import Section from '../Section.tsx';
import { Button } from '../ui/button.tsx';
import CreateOrderButton from './CreateOrderButton.tsx';
import { PharmaciesUnpaidTable } from './DataTable/PharmaciesUnpaidTable.tsx';
import ProcessingTimes from './ProcessingTimes.tsx';

interface NewRequestProps {
  request: IRequest;
  setView: (view: string) => void;
  save: (data: any) => void;
}
const RequestEdit = ({ request, setView, save }: NewRequestProps) => {
  const hasUnpaidPharmacies =
    request.pharmacies?.filter((pharmacy) => {
      return !request.tasks.some((task) => task.pharmacy_id === pharmacy.id);
    }) || [];

  return (
    <>
      <div className="flex items-center justify-between w-full h-18">
        <div className="flex items-center justify-start gap-1 mt-6 mb-6 sm:mt-6 ">
          <div className="mr-2 leading-snug text-gray-600 hover:text-violet-600">
            <Link to="/requests">
              <ArrowLeft />
            </Link>
          </div>
          <div className="hidden font-medium leading-snug text-gray-400 hover:text-violet-600 sm:inline-block">
            <Link to="/requests">Orders</Link>
          </div>
          <div className="hidden px-2 font-medium leading-snug text-gray-400 sm:inline-block">/</div>
          <div className="font-medium leading-snug text-violet-60 items-center0">
            Order #<span className="uppercase">{request.id.substring(0, 6)}</span>
          </div>
        </div>
        <div className="flex items-center justify-end gap-2">
          {request.tasks.length != 0 && (
            <Button onClick={() => setView('show')} className="gap-2">
              Results
            </Button>
          )}
          <CreateOrderButton requestId={request.id} />
        </div>
      </div>

      <div className="flex flex-col justify-between gap-4 mt-4 mb-8 md:flex-row sm:gap-8">
        <div className="flex flex-col">
          <div className="text-3xl font-bold leading-8 text-neutral-800 sm:text-2xl">
            {request.status_details.title}
          </div>
          <div className="max-w-3xl mt-2 text-sm font-normal leading-normal text-gray-500 sm:mt-3 md:mt-2 lg:mt-2 xl:mt-2 ">
            {request.status_details.text}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-12">
        <div className="flex flex-col gap-12 md:gap-8 lg:flex-row">
          <div className="flex-1">
            <MedicationList request={request as IRequest} />

            <div className="flex-1 hidden mt-8 sm:flex">
              <Section title="Enter any additional notes">
                <div className="w-full">
                  <Textarea
                    className="h-28"
                    placeholder="Please provide any extra details that Needle should know to help us find your medication."
                    defaultValue={request.note}
                    onBlur={(e) => save({ note: e.target.value })}
                  />
                </div>
              </Section>
            </div>
          </div>
          <div className="flex-1 w-full sm:flex-row ">
            <Location request={request} />
          </div>
        </div>

        <div className="flex-1 w-full sm:flex-row">
          <PharmaciesUnpaidTable request={request} />
        </div>

        <div className="block sm:hidden ">
          <Section title="Enter any additional notes">
            <div className="w-full">
              <Textarea
                className="h-28"
                placeholder="Please provide any extra details that Needle should know to help us find your medication."
                defaultValue={request.note}
                onBlur={(e) => save({ note: e.target.value })}
              />
            </div>
          </Section>
        </div>

        {hasUnpaidPharmacies.length > 0 && (
          <div className="flex flex-col gap-8 md:flex-row">
            <ProcessingTimes request={request} />
            <RequestSummary request={request} />
          </div>
        )}
      </div>
    </>
  );
};

export default RequestEdit;
