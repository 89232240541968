// useCallHistory.tsx
import { useQuery } from 'react-query';
import { ICall, IMedication, ITask, IPharmacy } from '@/types';
import axiosClient from '@/utils/axiosClient';

// Define ICallWithDetails locally
interface ICallWithDetails extends Omit<ICall, 'medication' | 'task'> {
  medication: IMedication;
  task: ITask & { stock_status: string; request_id: string };
  pharmacy: IPharmacy;
}

function useCallHistory(pharmacy_id: string) {
  return useQuery<ICallWithDetails[], Error>(
    ['callHistory', pharmacy_id],
    async () => {
      const response = await axiosClient.get(`/admin/pharmacies/${pharmacy_id}/call_history`);
      return response.data;
    },
    {
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
    },
  );
}

export default useCallHistory;
