import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '@/components/Loading';
import { Badge } from '@/components/ui/badge';
import { IPrompt, IPharmacyGroup, IPharmacy } from '@/types';
import axiosClient from '@/utils/axiosClient';

const PromptsAndGroupsPage: React.FC = () => {
  const [prompts, setPrompts] = useState<IPrompt[]>([]);
  const [archivedPrompts, setArchivedPrompts] = useState<IPrompt[]>([]);
  const [pharmacyGroups, setPharmacyGroups] = useState<IPharmacyGroup[]>([]);
  const [archivedPharmacyGroups, setArchivedPharmacyGroups] = useState<IPharmacyGroup[]>([]);
  const [promptName, setPromptName] = useState('');
  const [promptText, setPromptText] = useState('');
  const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([]);
  const [groupName, setGroupName] = useState('');
  const [searchPrompt, setSearchPrompt] = useState('');
  const [searchGroup, setSearchGroup] = useState('');
  const [searchArchivedPrompt, setSearchArchivedPrompt] = useState('');
  const [searchArchivedGroup, setSearchArchivedGroup] = useState('');
  const [showArchivedPrompts, setShowArchivedPrompts] = useState(false);
  const [showArchivedGroups, setShowArchivedGroups] = useState(false);
  const [editingPromptId, setEditingPromptId] = useState('');
  const [editingGroupId, setEditingGroupId] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [pharmacies, setPharmacies] = useState<IPharmacy[]>([]);
  const [selectedPharmacies, setSelectedPharmacies] = useState<string[]>([]);
  const [bulkGroupId, setBulkGroupId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [jumpToPage, setJumpToPage] = useState('');
  const [editingPhoneId, setEditingPhoneId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [tempItemsPerPage, setTempItemsPerPage] = useState(itemsPerPage.toString());
  const [promptType, setPromptType] = useState<string>('');
  const [isNameReadOnly, setIsNameReadOnly] = useState(false);

  useEffect(() => {
    fetchPrompts();
    fetchPharmacyGroups();
    searchPharmacies();
  }, [currentPage, itemsPerPage]);

  const fetchPrompts = () => {
    setLoading(true);
    Promise.all([
      axiosClient.get('/v1/prompts', { params: { archived: false } }),
      axiosClient.get('/v1/prompts', { params: { archived: true } }),
    ])
      .then(([activeResponse, archivedResponse]) => {
        setPrompts(activeResponse.data);
        setArchivedPrompts(archivedResponse.data);
      })
      .catch((error) => {
        console.error('Error fetching prompts:', error);
        toast.error('Failed to fetch prompts. Please try again later.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchPharmacyGroups = () => {
    setLoading(true);
    Promise.all([axiosClient.get('/v1/pharmacy_groups'), axiosClient.get('/v1/pharmacy_groups?archived=true')])
      .then(([activeResponse, archivedResponse]) => {
        setPharmacyGroups(activeResponse.data);
        setArchivedPharmacyGroups(archivedResponse.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching pharmacy groups:', error);
        toast.error('Failed to fetch pharmacy groups. Please try again later.');
        setLoading(false);
      });
  };

  const handleCreatePrompt = (e: React.FormEvent) => {
    e.preventDefault();
    if (!promptName || !promptText) {
      toast.warn('Please fill in all required fields for the prompt.');
      return;
    }
    setLoading(true);

    axiosClient
      .post('/v1/prompts', {
        name: promptName,
        prompt: promptText,
        prompt_type: promptType,
        pharmacy_group_ids: selectedGroupIds,
      })
      .then((response) => {
        if (response.status === 201) {
          toast.success('Prompt created successfully.');
          setPromptName('');
          setPromptText('');
          setPromptType('');
          setSelectedGroupIds([]);
          fetchPrompts();
        } else {
          throw new Error('Failed to create prompt');
        }
      })
      .catch((error) => {
        console.error('Error creating prompt:', error.response?.data?.errors);
        const errorMessage = error.response?.data?.errors?.join(', ') || 'Unknown error';
        toast.error(`Failed to create prompt: ${errorMessage}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdatePrompt = (e: React.FormEvent) => {
    e.preventDefault();
    if (!promptName || !promptText) {
      toast.warn('Please fill in all required fields for the prompt.');
      return;
    }
    setLoading(true);

    const originalPrompt = prompts.find((p) => p.id === editingPromptId);
    if (
      originalPrompt &&
      (originalPrompt.name === 'base' || originalPrompt.name === 'ChatServiceAnalysis') &&
      promptName !== originalPrompt.name
    ) {
      toast.error('You cannot change the name of this prompt.');
      setLoading(false);
      return;
    }

    axiosClient
      .put(`/v1/prompts/${editingPromptId}`, {
        name: promptName,
        prompt: promptText,
        prompt_type: promptType,
        pharmacy_group_ids: selectedGroupIds,
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success('Prompt updated successfully.');
          setPromptName('');
          setPromptText('');
          setPromptType('');
          setSelectedGroupIds([]);
          setEditingPromptId('');
          fetchPrompts();
        } else {
          throw new Error('Failed to update prompt');
        }
      })
      .catch((error) => {
        console.error('Error updating prompt:', error.response?.data?.errors);
        const errorMessage = error.response?.data?.errors?.join(', ') || 'Unknown error';
        toast.error(`Failed to update prompt: ${errorMessage}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleArchivePrompt = (promptId: string) => {
    const promptToArchive = prompts.find((p) => p.id === promptId);
    if (!promptToArchive) {
      toast.error('Prompt not found.');
      return;
    }

    if (
      promptToArchive.name === 'base' ||
      promptToArchive.name === 'ChatServiceAnalysis' ||
      (['base', 'summary', 'analysis'].includes(promptToArchive.name.toLowerCase()) &&
        promptToArchive.prompt_type !== '')
    ) {
      if (promptToArchive.name === 'base' || promptToArchive.name === 'ChatServiceAnalysis') {
        toast.error('Cannot archive the base or ChatServiceAnalysis prompts.');
      } else {
        toast.error('Cannot archive system prompts (base, summary, or analysis).');
      }
      return;
    }

    if (
      window.confirm(
        `Are you sure you want to archive the prompt "${promptToArchive.name}"? Doing so will remove the groups and type from this prompt.`,
      )
    ) {
      setLoading(true);
      axiosClient
        .post(`/v1/prompts/${promptId}/archive`)
        .then((response) => {
          const archivedPrompt = response.data;
          setPrompts(prompts.filter((prompt) => prompt.id !== promptId));
          setArchivedPrompts([...archivedPrompts, archivedPrompt]);
          toast.success('Prompt archived successfully.');
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error archiving prompt:', error);
          toast.error(`Failed to archive prompt: ${error.response?.data?.error || 'Unknown error'}`);
          setLoading(false);
        });
    }
  };

  const handleEditPrompt = (prompt: IPrompt) => {
    try {
      setPromptName(prompt.name || '');
      setPromptText(prompt.prompt || '');
      setPromptType(prompt.prompt_type || '');
      setSelectedGroupIds(prompt.pharmacy_groups?.map((group) => group.id) || []);
      setEditingPromptId(prompt.id);
      setIsNameReadOnly(prompt.name === 'base' || prompt.name === 'ChatServiceAnalysis');
    } catch (error) {
      console.error('Error in handleEditPrompt:', error);
    }
  };

  const handleGroupSelection = (groupId: string) => {
    setSelectedGroupIds((prevSelectedGroupIds) => {
      if (prevSelectedGroupIds.includes(groupId)) {
        return prevSelectedGroupIds.filter((id) => id !== groupId);
      } else {
        return [...prevSelectedGroupIds, groupId];
      }
    });
  };

  const handleCreateGroup = (e: React.FormEvent) => {
    e.preventDefault();
    if (!groupName) {
      toast.warn('Please enter a name for the pharmacy group.');
      return;
    }
    setLoading(true);
    axiosClient
      .post('/v1/pharmacy_groups', { name: groupName })
      .then(() => {
        toast.success('Pharmacy group created successfully.');
        setGroupName('');
        fetchPharmacyGroups();
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error creating group:', error.response?.data?.errors);
        toast.error(`Failed to create pharmacy group: ${error.response?.data?.errors?.join(', ') || 'Unknown error'}`);
        setLoading(false);
      });
  };

  const handleUpdateGroup = (e: React.FormEvent) => {
    e.preventDefault();
    if (!groupName) {
      toast.warn('Please enter a name for the pharmacy group.');
      return;
    }
    setLoading(true);
    axiosClient
      .put(`/v1/pharmacy_groups/${editingGroupId}`, { name: groupName })
      .then(() => {
        toast.success('Pharmacy group updated successfully.');
        setGroupName('');
        setEditingGroupId('');
        fetchPharmacyGroups();
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error updating group:', error.response?.data?.errors);
        toast.error(`Failed to update pharmacy group: ${error.response?.data?.errors?.join(', ') || 'Unknown error'}`);
        setLoading(false);
      });
  };

  const handleArchiveGroup = async (groupId: string) => {
    if (window.confirm('Are you sure you want to archive this pharmacy group?')) {
      setLoading(true);
      try {
        const response = await axiosClient.post(`/v1/pharmacy_groups/${groupId}/archive`);
        const archivedGroup = response.data;
        setPharmacyGroups((prevGroups) => prevGroups.filter((group) => group.id !== groupId));
        setArchivedPharmacyGroups((prevArchivedGroups) => [...prevArchivedGroups, archivedGroup]);
        setPrompts((prevPrompts) =>
          prevPrompts.map((prompt) => ({
            ...prompt,
            pharmacy_groups: prompt.pharmacy_groups.filter((group) => group.id !== groupId),
          })),
        );
        toast.success('Pharmacy group archived successfully.');
        setLoading(false);
      } catch (error) {
        console.error('Error archiving group:', error);
        toast.error(`Failed to archive pharmacy group: ${(error as any).response?.data?.error || 'Unknown error'}`);
        setLoading(false);
      }
    }
  };

  const handleUnarchiveGroup = (groupId: string) => {
    if (window.confirm('Are you sure you want to unarchive this pharmacy group?')) {
      setLoading(true);
      axiosClient
        .post(`/v1/pharmacy_groups/${groupId}/unarchive`)
        .then((response) => {
          const unarchivedGroup = response.data;
          setArchivedPharmacyGroups((prevArchivedGroups) => prevArchivedGroups.filter((group) => group.id !== groupId));
          setPharmacyGroups((prevGroups) => {
            const updatedGroups = [...prevGroups, unarchivedGroup];
            return updatedGroups.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
          });
          toast.success('Pharmacy group unarchived successfully.');
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error unarchiving group:', error);
          toast.error(`Failed to unarchive pharmacy group: ${error.response?.data?.error || 'Unknown error'}`);
          setLoading(false);
        });
    }
  };

  const handleEditGroup = (group: IPharmacyGroup) => {
    setGroupName(group.name);
    setEditingGroupId(group.id);
  };

  const handleUnarchivePrompt = (promptId: string) => {
    if (window.confirm('Are you sure you want to unarchive this prompt?')) {
      setLoading(true);
      axiosClient
        .post(`/v1/prompts/${promptId}/unarchive`)
        .then((response) => {
          const unarchivedPrompt = response.data;
          setArchivedPrompts((prevArchivedPrompts) => prevArchivedPrompts.filter((prompt) => prompt.id !== promptId));
          setPrompts((prevPrompts) => {
            const updatedPrompts = [...prevPrompts];
            const insertIndex = updatedPrompts.findIndex(
              (prompt) => new Date(prompt.created_at) < new Date(unarchivedPrompt.created_at),
            );
            if (insertIndex !== -1) {
              updatedPrompts.splice(insertIndex, 0, unarchivedPrompt);
            } else {
              updatedPrompts.push(unarchivedPrompt);
            }
            return updatedPrompts;
          });
          toast.success('Prompt unarchived successfully.');
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error unarchiving prompt:', error);
          toast.error(`Failed to unarchive prompt: ${error.response?.data?.error || 'Unknown error'}`);
          setLoading(false);
        });
    }
  };

  const handleDeletePrompt = (promptId: string) => {
    if (window.confirm('Are you sure you want to delete this prompt? This action cannot be undone.')) {
      setLoading(true);
      axiosClient
        .delete(`/v1/prompts/${promptId}`)
        .then(() => {
          toast.success('Prompt deleted successfully.');
          setArchivedPrompts(archivedPrompts.filter((prompt) => prompt.id !== promptId));
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error deleting prompt:', error);
          toast.error(`Failed to delete prompt: ${error.response?.data?.error || 'Unknown error'}`);
          setLoading(false);
        });
    }
  };

  const handleDeleteGroup = async (groupId: string) => {
    if (window.confirm('Are you sure you want to delete this pharmacy group? This action cannot be undone.')) {
      setLoading(true);
      try {
        await axiosClient.delete(`/v1/pharmacy_groups/${groupId}`);
        toast.success('Pharmacy group deleted successfully.');
        setArchivedPharmacyGroups((prevArchivedGroups) => prevArchivedGroups.filter((group) => group.id !== groupId));
        setPrompts((prevPrompts) =>
          prevPrompts.map((prompt) => ({
            ...prompt,
            pharmacy_groups: prompt.pharmacy_groups.filter((group) => group.id !== groupId),
          })),
        );
        setLoading(false);
      } catch (error) {
        console.error('Error deleting group:', error);
        toast.error(`Failed to delete pharmacy group: ${(error as any).response?.data?.error || 'Unknown error'}`);
        setLoading(false);
      }
    }
  };

  const searchPharmacies = async () => {
    setLoading(true);
    try {
      const response = await axiosClient.get('/v1/pharmacies/search', {
        params: {
          query: searchQuery,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });
      setPharmacies(response.data.pharmacies);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error searching pharmacies:', error);
      toast.error('Failed to search pharmacies. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    setCurrentPage(1);
    searchPharmacies();
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handlePharmacyGroupChange = async (pharmacyId: string, groupId: string) => {
    const group = pharmacyGroups.find((group) => group.id === groupId);
    setPharmacies((prevPharmacies) =>
      prevPharmacies.map((pharmacy) =>
        pharmacy.id === pharmacyId ? { ...pharmacy, pharmacy_group: group, pharmacy_group_id: groupId } : pharmacy,
      ),
    );

    try {
      await axiosClient.put(`/v1/pharmacies/${pharmacyId}/group`, { pharmacy_group_id: groupId });
    } catch (error) {
      console.error('Error updating pharmacy group:', error);
      toast.error('Failed to update pharmacy group. Please try again later.');
      setPharmacies((prevPharmacies) =>
        prevPharmacies.map((pharmacy) =>
          pharmacy.id === pharmacyId ? { ...pharmacy, pharmacy_group: undefined, pharmacy_group_id: null } : pharmacy,
        ),
      );
    }
  };

  const handlePharmacyPhoneChange = async (pharmacyId: string, phone: string) => {
    setPharmacies((prevPharmacies) =>
      prevPharmacies.map((pharmacy) => (pharmacy.id === pharmacyId ? { ...pharmacy, phone } : pharmacy)),
    );

    try {
      await axiosClient.put(`/v1/pharmacies/${pharmacyId}/phone`, { phone });
      setEditingPhoneId(null);
    } catch (error) {
      console.error('Error updating pharmacy phone:', error);
      toast.error('Failed to update pharmacy phone. Please try again later.');
      const originalPhone = pharmacies.find((ph) => ph.id === pharmacyId)?.phone || '';
      setPharmacies((prevPharmacies) =>
        prevPharmacies.map((pharmacy) =>
          pharmacy.id === pharmacyId ? { ...pharmacy, phone: originalPhone } : pharmacy,
        ),
      );
    }
  };

  const handleSelectPharmacy = (pharmacyId: string) => {
    setSelectedPharmacies((prevSelectedPharmacies) => {
      if (prevSelectedPharmacies.includes(pharmacyId)) {
        return prevSelectedPharmacies.filter((id) => id !== pharmacyId);
      } else {
        return [...prevSelectedPharmacies, pharmacyId];
      }
    });
  };

  const handleBulkUpdateGroups = useCallback(async () => {
    if (!bulkGroupId) {
      toast.warn('Please select a group to update.');
      return;
    }
    setLoading(true);
    try {
      await axiosClient.post('/v1/pharmacies/bulk_update_groups', {
        pharmacy_ids: selectedPharmacies,
        pharmacy_group_id: bulkGroupId,
      });
      toast.success('Pharmacy groups updated successfully.');
      setSelectedPharmacies([]);
      setBulkGroupId('');
      searchPharmacies();
      fetchPharmacyGroups();
      setLoading(false);
    } catch (error) {
      console.error('Error updating pharmacy groups:', error);
      toast.error(`Failed to update pharmacy groups: ${(error as any).response?.data?.error || 'Unknown error'}`);
      setLoading(false);
    }
  }, [selectedPharmacies, bulkGroupId, searchPharmacies, fetchPharmacyGroups]);

  const handleItemsPerPageSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const number = parseInt(tempItemsPerPage, 10);
    if (!isNaN(number) && number > 0) {
      if (number > 500) {
        setLoading(true);
      }
      setItemsPerPage(number);
    }
  };

  useEffect(() => {
    searchPharmacies();
  }, [itemsPerPage, currentPage]);

  const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempItemsPerPage(e.target.value);
  };

  const filteredPrompts = prompts
    .filter((prompt) => prompt.name.toLowerCase().includes(searchPrompt.toLowerCase()))
    .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

  const filteredGroups = pharmacyGroups
    .filter((group) => group.name.toLowerCase().includes(searchGroup.toLowerCase()))
    .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

  const filteredArchivedPrompts = archivedPrompts
    .filter((prompt) => prompt.name.toLowerCase().includes(searchArchivedPrompt.toLowerCase()))
    .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());

  const filteredArchivedGroups = archivedPharmacyGroups
    .filter((group) => group.name.toLowerCase().includes(searchArchivedGroup.toLowerCase()))
    .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());

  const displayPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (startPage > 1) {
      pageNumbers.unshift(1, '...');
    }

    if (endPage < totalPages) {
      pageNumbers.push('...', totalPages);
    }

    return pageNumbers;
  };

  return (
    <div className="container flex flex-col items-center p-4 mx-auto">
      <h1 className="p-2 mb-4 text-2xl font-bold text-center text-purple-600 rounded">Prompts and Pharmacy Groups</h1>

      <div className="flex flex-wrap justify-center w-full gap-4">
        <div className="flex-1 bg-white p-4 rounded shadow-md min-w-[300px]">
          <h2 className="mb-2 text-xl font-semibold text-purple-600">Prompts</h2>
          <input
            type="text"
            placeholder="Search Prompts..."
            value={searchPrompt}
            onChange={(e) => setSearchPrompt(e.target.value)}
            className="w-full p-2 mb-2 italic border rounded"
          />
          <div className="mb-4 overflow-y-scroll max-h-80">
            <ul className="space-y-2">
              {filteredPrompts.map((prompt) => (
                <li key={prompt.id + prompt.updated_at} className="p-4 bg-gray-100 rounded shadow">
                  <div className="flex items-center justify-between">
                    <div>
                      <div className="font-medium">{prompt.name}</div>
                      <div className="text-sm text-gray-600">{prompt.prompt}</div>
                      <div className="text-sm text-gray-500">
                        Groups:{' '}
                        {prompt.pharmacy_groups.map((group) => (
                          <Badge key={group.id} variant="pending" className="mr-2">
                            {group.name}
                          </Badge>
                        )) || 'N/A'}
                      </div>
                      <div className="text-sm text-gray-500">
                        Type:
                        {prompt.prompt_type && <Badge variant="processing">{prompt.prompt_type}</Badge>}
                      </div>
                    </div>
                    <div className="flex">
                      <button
                        onClick={() => handleEditPrompt(prompt)}
                        className="ml-4 text-blue-500 hover:text-blue-700"
                      >
                        Edit
                      </button>
                      {prompt.name !== 'base' && prompt.name !== 'ChatServiceAnalysis' && (
                        <button
                          onClick={() => handleArchivePrompt(prompt.id)}
                          className="ml-4 text-red-500 hover:text-red-700"
                        >
                          Archive
                        </button>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <hr className="mb-4 border-black" />
          <h3 className="mb-2 text-lg font-semibold text-purple-600">{editingPromptId ? 'Edit' : 'Create'} Prompt</h3>
          <form
            onSubmit={editingPromptId ? handleUpdatePrompt : handleCreatePrompt}
            className="p-4 space-y-4 bg-white rounded shadow"
          >
            <div>
              <label className="block font-medium">Name</label>
              <input
                type="text"
                value={promptName}
                onChange={(e) => setPromptName(e.target.value)}
                className={`w-full p-2 italic border rounded ${isNameReadOnly ? 'bg-gray-100' : ''}`}
                placeholder="Enter prompt name..."
                readOnly={isNameReadOnly}
              />
              {isNameReadOnly && (
                <p className="mt-1 text-sm text-gray-500 italic">This prompt's name cannot be changed.</p>
              )}
            </div>
            <div>
              <label className="block font-medium">Prompt</label>
              <textarea
                value={promptText}
                onChange={(e) => setPromptText(e.target.value)}
                className="w-full p-2 italic border rounded"
                placeholder="Enter prompt text..."
              />
            </div>
            <div>
              <label className="block font-medium">Prompt Type</label>
              <select
                value={promptType}
                onChange={(e) => setPromptType(e.target.value)}
                className="w-full p-2 italic border rounded"
              >
                <option value="">Select type...</option>
                <option value="base">Base</option>
                <option value="summary">Summary</option>
                <option value="analysis">Analysis</option>
              </select>
            </div>

            <div>
              <label className="block font-medium">Pharmacy Groups</label>
              <input
                type="text"
                placeholder="Search Groups..."
                value={searchGroup}
                onChange={(e) => setSearchGroup(e.target.value)}
                className="w-full p-2 mb-2 italic border rounded"
              />
              <div className="grid grid-cols-2 gap-2" style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                {filteredGroups.slice(0, 10).map((group) => (
                  <div
                    key={group.id}
                    className={`p-2 border rounded cursor-pointer ${
                      selectedGroupIds.includes(group.id) ? 'bg-green-200' : 'bg-white'
                    }`}
                    onClick={() => handleGroupSelection(group.id)}
                  >
                    <Badge variant="pending">{group.name}</Badge>
                  </div>
                ))}
              </div>
            </div>
            <button type="submit" className="w-full p-2 text-white bg-purple-600 rounded hover:bg-purple-700">
              {editingPromptId ? 'Update Prompt' : 'Create Prompt'}
            </button>
          </form>
          <hr className="mt-4 mb-4 border-black" />
          <div className="mt-4">
            <button
              className="w-full p-2 text-gray-700 bg-gray-300 rounded hover:bg-gray-400"
              onClick={() => setShowArchivedPrompts(!showArchivedPrompts)}
            >
              {showArchivedPrompts ? 'Hide' : 'Show'} Archived Prompts
            </button>
            {showArchivedPrompts && (
              <div className="mt-4">
                <input
                  type="text"
                  placeholder="Search Archived Prompts..."
                  value={searchArchivedPrompt}
                  onChange={(e) => setSearchArchivedPrompt(e.target.value)}
                  className="w-full p-2 mb-2 italic border rounded"
                />
                <div className="overflow-y-scroll max-h-40">
                  <ul className="space-y-2">
                    {filteredArchivedPrompts.map((prompt) => (
                      <li key={prompt.id} className="p-4 bg-gray-200 rounded shadow">
                        <div className="font-medium">{prompt.name}</div>
                        <div className="text-sm text-gray-600">{prompt.prompt}</div>
                        <div className="text-sm text-gray-500">
                          Groups:{' '}
                          {prompt.pharmacy_groups.map((group) => (
                            <Badge key={group.id} variant="default" className="mr-2">
                              {group.name}
                            </Badge>
                          ))}
                        </div>
                        <div className="text-sm text-gray-500">
                          Type:
                          {prompt.prompt_type && <Badge variant="processing">{prompt.prompt_type}</Badge>}
                        </div>
                        <button
                          onClick={() => handleUnarchivePrompt(prompt.id)}
                          className="mt-2 text-green-500 hover:text-green-700"
                        >
                          Unarchive
                        </button>
                        <button
                          onClick={() => handleDeletePrompt(prompt.id)}
                          className="mt-2 ml-4 text-red-500 hover:text-red-700"
                        >
                          Delete
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex-1 bg-white p-4 rounded shadow-md min-w-[300px]">
          <h2 className="mb-2 text-xl font-semibold text-purple-600">Pharmacy Groups</h2>
          <input
            type="text"
            placeholder="Search Groups..."
            value={searchGroup}
            onChange={(e) => setSearchGroup(e.target.value)}
            className="w-full p-2 mb-2 italic border rounded"
          />
          <div className="mb-4 overflow-y-scroll max-h-80">
            <ul className="space-y-2">
              {filteredGroups.map((group) => (
                <li key={group.id} className="p-4 bg-gray-100 rounded shadow">
                  <div className="flex items-center justify-between">
                    <div>
                      <Badge variant="pending">{group.name}</Badge>
                    </div>
                    <div>
                      <button onClick={() => handleEditGroup(group)} className="ml-4 text-blue-500 hover:text-blue-700">
                        Edit
                      </button>
                      <button
                        onClick={() => handleArchiveGroup(group.id)}
                        className="ml-4 text-red-500 hover:text-red-700"
                      >
                        Archive
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <hr className="mb-4 border-black" />
          <h3 className="mb-2 text-lg font-semibold text-purple-600">
            {editingGroupId ? 'Edit' : 'Create'} Pharmacy Group
          </h3>
          <form
            onSubmit={editingGroupId ? handleUpdateGroup : handleCreateGroup}
            className="p-4 space-y-4 bg-white rounded shadow"
          >
            <div>
              <label className="block font-medium">Name</label>
              <input
                type="text"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                className="w-full p-2 italic border rounded"
                placeholder="Enter group name..."
              />
            </div>
            <button type="submit" className="w-full p-2 text-white bg-purple-600 rounded hover:bg-purple-700">
              {editingGroupId ? 'Update Group' : 'Create Group'}
            </button>
          </form>
          <hr className="mt-4 mb-4 border-black" />
          <div className="mt-4">
            <button
              className="w-full p-2 text-gray-700 bg-gray-300 rounded hover:bg-gray-400"
              onClick={() => setShowArchivedGroups(!showArchivedGroups)}
            >
              {showArchivedGroups ? 'Hide' : 'Show'} Archived Groups
            </button>
            {showArchivedGroups && (
              <div className="mt-4">
                <input
                  type="text"
                  placeholder="Search Archived Groups..."
                  value={searchArchivedGroup}
                  onChange={(e) => setSearchArchivedGroup(e.target.value)}
                  className="w-full p-2 mb-2 italic border rounded"
                />
                <div className="overflow-y-scroll max-h-40">
                  <ul className="space-y-2">
                    {filteredArchivedGroups.map((group) => (
                      <li key={group.id} className="p-4 bg-gray-200 rounded shadow">
                        <div>
                          <Badge variant="default">{group.name}</Badge>
                        </div>
                        <button
                          onClick={() => handleUnarchiveGroup(group.id)}
                          className="mt-2 text-green-500 hover:text-green-700"
                        >
                          Unarchive
                        </button>
                        <button
                          onClick={() => handleDeleteGroup(group.id)}
                          className="mt-2 ml-4 text-red-500 hover:text-red-700"
                        >
                          Delete
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="w-full mt-8">
          <h2 className="mb-2 text-xl font-semibold text-purple-600">Pharmacy Management</h2>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <select
                value={bulkGroupId}
                onChange={(e) => setBulkGroupId(e.target.value)}
                className="p-2 italic text-gray-500 border rounded"
                style={{ width: '160px' }}
              >
                <option value="">Select Group...</option>
                {pharmacyGroups.map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </select>
              <button
                onClick={handleBulkUpdateGroups}
                className="p-2 ml-3 text-white bg-purple-600 rounded hover:bg-purple-700"
              >
                Update
              </button>
            </div>
            <form onSubmit={handleSearch} className="flex items-center">
              <input
                type="text"
                placeholder="Search for pharmacies by name..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-64 p-2 italic border rounded"
              />
              <button type="submit" className="p-2 ml-3 text-white bg-purple-600 rounded hover:bg-purple-700">
                Search
              </button>
            </form>
          </div>
          {loading ? (
            <div className="flex items-center justify-center">
              <Loading />
              <span className="ml-2 italic text-gray-500">Loading...</span>
            </div>
          ) : pharmacies.length > 0 ? (
            <>
              <table className="w-full table-auto">
                <thead>
                  <tr>
                    <th className="w-1/12 px-4 py-2 text-left">#</th>
                    <th className="w-1/12 px-4 py-2 text-left">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedPharmacies(pharmacies.map((pharmacy) => pharmacy.id));
                          } else {
                            setSelectedPharmacies([]);
                          }
                        }}
                        checked={selectedPharmacies.length === pharmacies.length}
                      />
                    </th>
                    <th className="w-2/12 px-4 py-2 text-left">Name</th>
                    <th className="w-2/12 px-4 py-2 text-left">Address</th>
                    <th className="w-1/12 px-4 py-2 text-left">City</th>
                    <th className="w-1/12 px-4 py-2 text-left">State</th>
                    <th className="w-1/12 px-4 py-2 text-left">Zip</th>
                    <th className="w-2/12 px-4 py-2 text-left">Phone</th>
                    <th className="w-1/12 px-4 py-2 text-left">Group</th>
                  </tr>
                </thead>
                <tbody>
                  {pharmacies.map((pharmacy, index) => (
                    <tr key={pharmacy.id} className="border-t">
                      <td className="px-4 py-2">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                      <td className="px-4 py-2">
                        <input
                          type="checkbox"
                          onChange={() => handleSelectPharmacy(pharmacy.id)}
                          checked={selectedPharmacies.includes(pharmacy.id)}
                        />
                      </td>
                      <td className="px-4 py-2">{pharmacy.name}</td>
                      <td className="px-4 py-2">{pharmacy.address1}</td>
                      <td className="px-4 py-2">{pharmacy.city}</td>
                      <td className="px-4 py-2">{pharmacy.state}</td>
                      <td className="px-4 py-2">{pharmacy.zip}</td>
                      <td className="px-4 py-2">
                        {editingPhoneId === pharmacy.id ? (
                          <div className="flex items-center">
                            <input
                              type="text"
                              defaultValue={pharmacy.phone}
                              onBlur={(e) => handlePharmacyPhoneChange(pharmacy.id, e.target.value)}
                              className="w-full p-1 border rounded"
                            />
                            <button
                              onClick={() => handlePharmacyPhoneChange(pharmacy.id, pharmacy.phone)}
                              className="ml-2 text-green-500 hover:text-green-700"
                            >
                              ✓
                            </button>
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <span>{pharmacy.phone}</span>
                            <button
                              onClick={() => setEditingPhoneId(pharmacy.id)}
                              className="ml-2 text-blue-500 hover:text-blue-700"
                            >
                              Edit
                            </button>
                          </div>
                        )}
                      </td>
                      <td className="px-4 py-2">
                        <select
                          value={pharmacy.pharmacy_group?.id || ''}
                          onChange={(e) => handlePharmacyGroupChange(pharmacy.id, e.target.value)}
                          className={`w-full p-2 border rounded italic ${
                            pharmacy.pharmacy_group ? 'bg-purple-100' : 'text-gray-500 italic'
                          }`}
                          style={{ width: '160px' }}
                        >
                          <option value="" className="italic">
                            Select Group...
                          </option>
                          {pharmacyGroups.map((group) => (
                            <option key={group.id} value={group.id}>
                              {group.name}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex items-center justify-between mt-4">
                <form onSubmit={handleItemsPerPageSubmit} className="flex items-center">
                  <label className="mr-2">Items per page:</label>
                  <input
                    type="number"
                    value={tempItemsPerPage}
                    onChange={handleItemsPerPageChange}
                    className="w-20 p-2 italic border rounded"
                    min="1"
                  />
                </form>
                {itemsPerPage > 500 && loading && (
                  <div className="flex items-center ml-2">
                    <Loading />
                    <span className="ml-2 italic text-gray-500">Loading... this might take a minute...</span>
                  </div>
                )}

                <div className="flex items-center">
                  {displayPageNumbers().map((page, index) =>
                    typeof page === 'string' ? (
                      <span key={index} className="mx-2">
                        {page}
                      </span>
                    ) : (
                      <button
                        key={page}
                        onClick={() => handlePageChange(page)}
                        disabled={page === currentPage}
                        className={`mx-1 px-2 py-1 border rounded ${
                          page === currentPage
                            ? 'bg-purple-600 text-white'
                            : 'bg-white text-purple-600 hover:bg-purple-100'
                        }`}
                      >
                        {page}
                      </button>
                    ),
                  )}
                  <input
                    type="text"
                    value={jumpToPage}
                    onChange={(e) => setJumpToPage(e.target.value)}
                    placeholder="Jump to page --->"
                    className="p-1 mx-2 italic border rounded"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        const pageNumber = Number(jumpToPage);
                        if (pageNumber > 0 && pageNumber <= totalPages) {
                          setCurrentPage(pageNumber);
                          setJumpToPage('');
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <p>No pharmacies found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PromptsAndGroupsPage;
