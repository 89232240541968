import React, { useCallback, useState, useEffect } from 'react';
import { Phone, ChevronDown, ChevronUp, Mic, MicOff, PhoneOff } from 'lucide-react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import useMe from '@/hooks/useMe';
import { IRequest, ITask } from '@/types';
import axiosClient from '@/utils/axiosClient';
import { formatPhoneNumber } from '@/utils/helpers';
import { cn } from '@/utils/utils';
import { useCallContext } from './CallContext';

interface PhonePanelProps {
  task: ITask;
}

export default function PhonePanel({ task }: PhonePanelProps) {
  const { data: currentUser } = useMe();
  const queryClient = useQueryClient();
  const { device, activeCall, isMuted, callDuration, setActiveCall, setIsMuted, endCall, startCall, initializeDevice } =
    useCallContext();

  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>(task.pharmacy.phone ?? '');

  useEffect(() => {
    initializeDevice();
  }, [initializeDevice]);

  useEffect(() => {
    let pollInterval: NodeJS.Timeout;
    if (activeCall) {
      pollInterval = setInterval(() => {
        queryClient.invalidateQueries(['task', task.id]);
      }, 5000);
    }
    return () => {
      if (pollInterval) clearInterval(pollInterval);
    };
  }, [activeCall, queryClient, task.id]);

  const handleCallDisconnect = useCallback(
    (initiatedByUser: boolean) => {
      endCall();
      if (!initiatedByUser) {
        toast.info('Call ended', { autoClose: 2000 });
      }
      const pollInterval = setInterval(() => {
        queryClient.invalidateQueries(['task', task.id]);
      }, 5000);
      setTimeout(() => {
        clearInterval(pollInterval);
      }, 30000);
    },
    [endCall, queryClient, task.id],
  );

  const handleStartCall = useCallback(async () => {
    if (device && phoneNumber && currentUser) {
      try {
        const response = await axiosClient.post(`/twilio/dial/${task.id}`, {
          phone: phoneNumber,
          user_id: currentUser.id,
        });
        if (response.data) {
          const oldRequestData = queryClient.getQueryData<IRequest>([`admin/requests/${task.request_id}`]);
          queryClient.setQueryData([`admin/requests/${task.request_id}`], {
            ...oldRequestData,
            tasks: oldRequestData?.tasks.map((t) => (t.id === task.id ? response.data.task : t)),
          });
          queryClient.setQueryData([`admin/tasks/${task.id}`], response.data.task);
          startCall();
          const call = await device.connect({
            params: {
              to: phoneNumber,
              from: response.data.from,
              needle_task_id: response.data.needle_task_id,
              needle_call_id: response.data.needle_call_id,
            },
          });
          call.on('disconnect', () => {
            handleCallDisconnect(false);
          });
          setActiveCall(call);
          setIsPanelOpen(true);
        } else {
          throw new Error('No destination number received from the server.');
        }
      } catch (error) {
        console.error('Error initiating call:', error);
        toast.error('Error initiating call. Please try again.', { autoClose: 2000 });
      }
    } else {
      toast.error('Calling system is not ready. Please try again in a moment.', { autoClose: 2000 });
    }
  }, [device, phoneNumber, handleCallDisconnect, queryClient, task.id, task.request_id, setActiveCall, startCall]);

  const togglePanel = () => setIsPanelOpen(!isPanelOpen);

  const toggleMute = useCallback(() => {
    if (activeCall) {
      activeCall.mute(!isMuted);
      setIsMuted(!isMuted);
    }
  }, [activeCall, isMuted, setIsMuted]);

  const handleSendDTMF = useCallback(
    (digit: string) => {
      if (activeCall) {
        activeCall.sendDigits(digit);
      } else if (phoneNumber.length < 10) {
        setPhoneNumber((prev) => prev + digit);
      }
      const audio = new Audio(`/path_to_dtmf_sounds/${digit}.mp3`);
      audio.play();
    },
    [activeCall, phoneNumber],
  );

  const handlePhoneNumberChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/\D/g, '');
    if (input.length <= 10) {
      setPhoneNumber(input);
    }
  }, []);

  const formatDuration = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="flex justify-center w-full">
      <Card className="w-[180px] bg-[#5E4DB2] text-white shadow-xl rounded-xl overflow-hidden">
        <CardContent className={cn('p-3 transition-all duration-300', isPanelOpen ? 'space-y-3' : 'space-y-1')}>
          {activeCall && (
            <div className="text-center text-sm font-semibold bg-[#7E6AD8] py-1 px-2 rounded-md mb-2">
              {formatDuration(callDuration)}
            </div>
          )}
          <div className="text-center">
            <div className="inline-block relative">
              <Input
                value={formatPhoneNumber(phoneNumber)}
                onChange={handlePhoneNumberChange}
                className="text-sm font-bold text-center bg-transparent border-none focus:ring-0 text-white py-1 px-2"
                maxLength={14}
              />
              <div className="absolute inset-y-1 inset-x-2 border border-white/20 rounded-md pointer-events-none"></div>
            </div>
          </div>

          <div
            className={cn(
              'transition-all duration-300 space-y-3',
              isPanelOpen ? 'max-h-[240px] opacity-100 mb-3' : 'max-h-0 opacity-0 overflow-hidden mb-0',
            )}
          >
            <div className="grid grid-cols-3 gap-2 justify-items-center">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#'].map((digit) => (
                <Button
                  key={digit}
                  variant="ghost"
                  size="icon"
                  className="w-11 h-11 rounded-full bg-[#7E6AD8] hover:bg-[#9A8AE5] text-white text-lg font-semibold"
                  onClick={() => handleSendDTMF(digit.toString())}
                >
                  {digit}
                </Button>
              ))}
            </div>
            {activeCall && (
              <div className="flex justify-center space-x-2">
                <Button
                  variant="ghost"
                  size="icon"
                  className={cn(
                    'w-11 h-11 bg-[#7E6AD8] hover:bg-[#9A8AE5] text-white rounded-full',
                    isMuted && 'bg-[#FFD166] hover:bg-[#FFE066]',
                  )}
                  onClick={toggleMute}
                >
                  {isMuted ? <MicOff className="h-5 w-5" /> : <Mic className="h-5 w-5" />}
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  className="w-11 h-11 bg-[#FF6B6B] hover:bg-[#FF8787] text-white rounded-full"
                  onClick={() => handleCallDisconnect(true)}
                >
                  <PhoneOff className="h-5 w-5" />
                </Button>
              </div>
            )}
          </div>

          <div className="flex items-center justify-between px-1">
            <Button
              variant="ghost"
              size="icon"
              className="w-8 h-8 text-white hover:bg-[#7E6AD8] p-0.5"
              onClick={togglePanel}
            >
              {isPanelOpen ? <ChevronUp className="h-5 w-5" /> : <ChevronDown className="h-5 w-5" />}
            </Button>
            {!isPanelOpen && activeCall ? (
              <Button
                variant="phonePanel"
                size="phonePanel"
                className="flex items-center justify-center bg-[#FF6B6B] hover:bg-[#FF8787] text-white rounded-full"
                onClick={() => handleCallDisconnect(true)}
              >
                <PhoneOff className="mr-1 h-4 w-4" />
                End Call
              </Button>
            ) : !activeCall ? (
              <Button
                variant="phonePanel"
                size="phonePanel"
                className="flex items-center justify-center bg-[#4ADE80] hover:bg-[#22C55E] text-white rounded-full"
                onClick={handleStartCall}
              >
                <Phone className="mr-1 h-4 w-4" />
                Start Call
              </Button>
            ) : null}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
