import { IAccount } from '@/types.ts';
import { getUrl } from '@/utils/api';

function useAccounts() {
  const query = getUrl(`admin/accounts`);
  return {
    ...query,
    data: query.data as IAccount[],
  };
}

export default useAccounts;
