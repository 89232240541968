import AdminRequestLoader from '@/components/Admin/Requests/RequestLoader.tsx';
import Login from '@/components/External/Login.tsx';
import MFA from '@/components/External/MFA.tsx';
import RequestCreator from '@/components/Requests/RequestCreator.tsx';
import RequestLoader from '@/components/Requests/RequestLoader.tsx';
import Account from './components/Accounts/Account.tsx';
import Accounts from './components/Accounts/Accounts.tsx';
import Pharmacies from './components/Admin/Pharmacies/Pharmacies';
import Pharmacy from './components/Admin/Pharmacies/Pharmacy';
import AdminUserProfile from './components/Admin/Profile/Profile';
import PromptsAndGroupsPage from './components/Admin/Prompts/PromptsAndGroupsPage.tsx';
import Tickets from './components/Admin/Tickets/Tickets.tsx';
import AdminUserList from './components/Admin/Users/List';
import AvailabilityTool from './components/AvailabilityTool/AvailabilityTool.tsx';
import Logout from './components/External/Logout.tsx';
import ExternalWrapper from './components/ExternalWrapper.tsx';
import Requests from './components/Requests/List.tsx';
import TextMessaging from './components/TextMessaging/TextMessaging.tsx';
import Application from './pages/Application';
import Medications from './pages/Medications.tsx';

const routes = [
  {
    url: '/',
    element: <Application />,
    private: false,
  },
  {
    url: '/login',
    exact: true,
    element: (
      <ExternalWrapper>
        <Login />
      </ExternalWrapper>
    ),
    private: false,
  },
  {
    url: '/mfa/:method/:value/:token',
    exact: true,
    element: (
      <ExternalWrapper>
        <MFA />
      </ExternalWrapper>
    ),
    private: false,
  },
  {
    url: '/logout',
    exact: true,
    element: <Logout />,
    private: true,
  },
  {
    url: '/accounts',
    exact: true,
    element: <Accounts />,
    private: true,
  },
  {
    url: '/accounts/:accountId',
    exact: true,
    element: <Account />,
    private: true,
  },
  {
    url: '/medications',
    exact: true,
    element: <Medications />,
    private: true,
  },
  {
    url: '/availabity-tool',
    exact: true,
    element: <AvailabilityTool />,
    private: true,
  },
  {
    url: '/requests',
    exact: true,
    element: <Requests />,
    private: true,
  },
  {
    url: '/requests/new',
    exact: true,
    element: <RequestCreator />,
    private: true,
  },
  {
    url: '/requests/:requestId/success',
    exact: true,
    element: <RequestLoader />,
    private: true,
  },
  {
    url: '/requests/:requestId/cancel',
    exact: true,
    element: <RequestLoader />,
    private: true,
  },
  {
    url: '/requests/:requestId',
    exact: true,
    element: <RequestLoader />,
    private: true,
  },
  {
    url: '/admin/pharmacies',
    exact: true,
    element: <Pharmacies />,
    private: true,
  },
  {
    url: '/admin/pharmacies/:id',
    exact: true,
    element: <Pharmacy />,
    private: true,
  },
  {
    url: '/admin/requests/:requestId',
    exact: true,
    element: <AdminRequestLoader />,
    private: true,
  },
  {
    url: '/admin/requests/:requestId/:taskId',
    exact: true,
    element: <AdminRequestLoader />,
    private: true,
  },
  {
    url: '/admin/requests/:requestId/:taskId/:callId',
    exact: true,
    element: <AdminRequestLoader />,
    private: true,
  },
  {
    url: '/admin/users/:id',
    exact: true,
    element: <AdminUserProfile />,
    private: true,
  },

  {
    url: '/admin/users',
    exact: true,
    element: <AdminUserList />,
    private: true,
  },
  {
    url: '/admin/tickets',
    exact: true,
    element: <Tickets />,
    private: true,
  },
  {
    url: '/admin/prompts',
    exact: true,
    element: <PromptsAndGroupsPage />,
    private: true,
  },
  {
    url: '/messages/:userId',
    exact: true,
    element: <TextMessaging />,
    private: true,
  },
  {
    url: '/messages',
    exact: true,
    element: <TextMessaging />,
    private: true,
  },
];

export { routes };
