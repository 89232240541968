import { ITextMessage } from '@/types.ts';
import { getUrl } from '@/utils/api';

function useTextMessages(userId: string) {
  const query = getUrl(`v2/text_messages/${userId}`, {
    refetchInterval: 10000,
    enabled: !!userId,
  });
  return {
    ...query,
    data: query.data as ITextMessage[],
  };
}

export default useTextMessages;
