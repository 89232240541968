import { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import useAccountPatients from '@/hooks/useAccountPatients';
import { IAccountPatient } from '@/types';
import DataTable from '../DataTable/DataTable';
import Section from '../Section';
import Time from '../Time';
import { Button } from '../ui/button';

const AccountPatients = () => {
  const accountPatients = useAccountPatients();
  const data = useMemo(() => accountPatients.data || [], [accountPatients.data]);

  const columns: ColumnDef<IAccountPatient>[] = useMemo(
    () => [
      {
        accessorKey: 'user.name',
        header: 'Patient',
      },
      {
        accessorKey: 'user.id',
        header: 'ID',
        cell: ({ row }) => (
          <Button variant="outline" asChild>
            <Link to={`/admin/users/${row.original.user.id}`}>{row.original.user.id}</Link>
          </Button>
        ),
      },
      {
        accessorKey: 'user.email',
        header: 'Email',
      },
      {
        accessorKey: 'user.dob',
        header: 'DOB',
      },
      {
        accessorKey: 'created_at',
        header: 'Invited',
        cell: ({ row }) => <Time>{row.original.created_at}</Time>,
      },
    ],
    [],
  );

  return (
    <Section>
      <DataTable data={data} columns={columns} model={accountPatients} placeholder={'Search patients...'} />
    </Section>
  );
};

export default AccountPatients;
