import { LockIcon } from 'lucide-react';
import { BsFilterSquareFill } from 'react-icons/bs';
import { FaSms, FaTasks, FaUsers } from 'react-icons/fa';
import { FaBuilding } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import Logo from '@/assets/logo-white.svg';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { classNames } from '@/utils/helpers';

const navigation = [
  {
    name: 'Tasks',
    href: '/admin/tickets',
    icon: FaTasks,
    current: false,
  },
  {
    name: 'Accounts',
    href: '/accounts',
    icon: FaBuilding,
    current: false,
  },
  {
    name: 'Users',
    href: '/admin/users',
    icon: FaUsers,
    current: false,
  },
  {
    name: 'Prompts & Groups',
    href: '/admin/prompts',
    icon: BsFilterSquareFill,
    current: false,
  },
  {
    name: 'Messages',
    href: '/messages',
    icon: FaSms,
    current: false,
  },
];

interface SidebarProps {
  closeDrawer?: () => void;
}

const Sidebar = ({ closeDrawer }: SidebarProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (closeDrawer) {
      closeDrawer();
    }
    navigate('/logout');
  };

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>, href: string) => {
    if (!e.ctrlKey && !e.metaKey) {
      e.preventDefault();
      if (closeDrawer) {
        closeDrawer();
      }
      navigate(href);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-8 px-0 py-8 overflow-y-auto sm:p-8 bg-brand-dark-blue grow rounded-2xl">
        <div className="cursor-pointer shrink-0" onClick={() => window.location.reload()}>
          <img src={Logo} alt="Needle Logo" className="h-8" />
        </div>
        <nav className="flex flex-col flex-1">
          <ul role="list" className="flex flex-col flex-1 gap-y-7">
            <li>
              <ul role="list" className="-mx-2 space-y-1">
                {navigation.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      onClick={(e) => handleLinkClick(e, item.href)}
                      className={classNames(
                        item.href === window.location.pathname
                          ? 'bg-brand-dark-blue-lighter text-white  border-[#413683] '
                          : 'text-white hover:text-white hover:bg-brand-dark-blue-lighter hover:border hover:border-[#413683]',
                        'w-full group flex gap-x-3 rounded-lg p-2 text-sm leading-6 font-semibold border border-transparent items-center cursor-pointer',
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.current ? 'text-white' : 'text-white group-hover:text-white',
                          'h-5 w-5 shrink-0',
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </li>

            <li className="mt-auto">
              <div className="mt-4 -ml-1.5">
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <button
                      type="button"
                      className="flex items-center w-full gap-4 px-4 py-2 text-sm font-semibold text-white border border-transparent rounded-md bg-brand-red hover:text-white hover:bg-brand-dark-blue-lighter hover:border hover:border-[#413683]"
                    >
                      <LockIcon className="w-5 h-5 " aria-hidden="true" />
                      Logout
                    </button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>Are you sure you want to logout?</AlertDialogTitle>
                      <AlertDialogDescription>We will miss you! Thanks for using Needle.</AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Stay it's cozy here</AlertDialogCancel>
                      <AlertDialogAction onClick={handleClick}>I will BRB</AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
