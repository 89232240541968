import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import Loading from '@/components/Loading';
import { StatusIndicator } from '@/components/Requests/StatusIndicator';
import Time from '@/components/Time';
import { Button } from '@/components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import useCallHistory from '@/hooks/useCallHistory';
import { IPharmacy, ICall, IMedication, ITask } from '@/types';
import { splitEmail } from '@/utils/emailUtils';
import { cn } from '@/utils/utils';

interface ICallWithDetails extends Omit<ICall, 'medication' | 'task'> {
  medication: IMedication;
  task: ITask & { stock_status: string; request_id: string };
  pharmacy: IPharmacy;
  user_email?: string;
}

interface PharmacyCallHistoryProps {
  pharmacy: IPharmacy;
  onUpdateStatus: (taskId: string, status: string) => Promise<void>;
}

const PharmacyCallHistory: React.FC<PharmacyCallHistoryProps> = React.memo(({ pharmacy, onUpdateStatus }) => {
  const { data: calls, isLoading, error } = useCallHistory(pharmacy.id);

  const statusButtons = useCallback(
    (call: ICallWithDetails) => (
      <div className="flex flex-col gap-1">
        <Button
          size="sm"
          className={cn(
            'w-full h-6 p-0 text-xs text-white bg-green-500',
            call.task.stock_status === 'in_stock' && 'bg-primary',
          )}
          onClick={() => onUpdateStatus(call.task.id, 'in_stock')}
        >
          IS
        </Button>
        <Button
          size="sm"
          className={cn(
            'w-full h-6 p-0 text-xs text-white bg-red-500',
            call.task.stock_status === 'out_of_stock' && 'bg-primary',
          )}
          onClick={() => onUpdateStatus(call.task.id, 'out_of_stock')}
        >
          OS
        </Button>
        <Button
          size="sm"
          className={cn(
            'w-full h-6 p-0 text-xs text-white bg-yellow-500',
            call.task.stock_status === 'invalid' && 'bg-primary',
          )}
          onClick={() => onUpdateStatus(call.task.id, 'invalid')}
        >
          IN
        </Button>
      </div>
    ),
    [onUpdateStatus],
  );

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div className="text-red-500">Error loading call history: {(error as any).message || 'Unknown error'}</div>;
  }

  return (
    <div className="overflow-x-auto">
      <Table className="text-xs table-auto w-full">
        <TableHeader>
          <TableRow>
            <TableHead className="whitespace-nowrap">Status</TableHead>
            <TableHead className="whitespace-nowrap">Actions</TableHead>
            <TableHead className="whitespace-nowrap">Date</TableHead>
            <TableHead className="whitespace-nowrap">Medication</TableHead>
            <TableHead className="whitespace-nowrap">Variant</TableHead>
            <TableHead className="whitespace-nowrap">Dosage</TableHead>
            <TableHead className="whitespace-nowrap">Preference</TableHead>
            <TableHead className="whitespace-nowrap">Qty</TableHead>
            <TableHead className="whitespace-nowrap">User Email</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {calls && calls.length > 0 ? (
            calls.map((call: ICallWithDetails) => {
              const email = call.user_email || 'N/A';
              const [localPart, domainPart] = splitEmail(email);
              return (
                <TableRow key={call.id}>
                  <TableCell>
                    <StatusIndicator status={call.task.stock_status} />
                  </TableCell>
                  <TableCell>{statusButtons(call)}</TableCell>
                  <TableCell>
                    <Time>{call.created_at}</Time>
                  </TableCell>
                  <TableCell>{call.medication.medication}</TableCell>
                  <TableCell>{call.medication.variant}</TableCell>
                  <TableCell>
                    {call.medication.dosage} {call.medication.dosage_form}
                  </TableCell>
                  <TableCell>{call.medication.preference}</TableCell>
                  <TableCell>{call.medication.quantity}</TableCell>
                  <TableCell className="max-w-sm px-2">
                    {email !== 'N/A' ? (
                      <Link to={`/admin/requests/${call.task.request_id}`} className="text-blue-600 hover:underline">
                        <div>
                          <span className="block">{localPart}</span>
                          <span className="block">{domainPart}</span>
                        </div>
                      </Link>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={9} className="text-center">
                No call history for this pharmacy
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
});

export default PharmacyCallHistory;
