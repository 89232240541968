import { IAccount } from '@/types.ts';
import { getUrl } from '@/utils/api';

function useAccount(accountId: string) {
  const query = getUrl(`v2/accounts/${accountId}`);
  return {
    ...query,
    data: query.data as IAccount,
  };
}

export default useAccount;
