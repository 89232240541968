import { useState } from 'react';
import { MessageSquareIcon } from 'lucide-react';
import { Link, useParams } from 'react-router-dom';
import Loading from '@/components/Loading.tsx';
import Section from '@/components/Section';
import Time from '@/components/Time';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import useUser from '@/hooks/useUser.ts';
import { formatPhoneNumber, USDollar, formatDateMMDDYYYY } from '@/utils/helpers';
import CreditDebitTool from './CreditDebitTool';

const Profile = () => {
  const { id } = useParams();
  const user = useUser(id as string);
  const [isCreditDebitToolOpen, setIsCreditDebitToolOpen] = useState(false);

  if (user.isLoading) {
    return <Loading />;
  }

  const requests = user.data?.requests || [];
  const orderedRequests = requests.sort((a, b) => {
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  });

  const pharmacies = user.data?.pharmacies || [];
  const uniquePharmacies = pharmacies.filter(
    (pharmacy, index, self) => index === self.findIndex((t) => t.id === pharmacy.id),
  );

  const sortedCreditTransactions =
    user.data?.credit_transactions?.sort(
      (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    ) || [];

  return (
    <>
      <div className="flex flex-row justify-between gap-8 mb-4">
        <div className="">
          <h1 className="text-xl font-semibold text-gray-900">
            {user.data?.first_name} {user.data?.last_name}
          </h1>
          <div className="mt-2 text-xl">{user.data?.email}</div>
          <div className="mt-2">
            Created:&nbsp;
            <Time>{user.data?.created_at}</Time>
          </div>
        </div>
        <div className="">
          <Button className="gap-2" asChild>
            <Link to={`/messages/${user.data?.id}`} className="gap-2">
              <MessageSquareIcon className="w-6 h-6" />
              Text Messages
            </Link>
          </Button>
        </div>
        <div className="flex h-12 gap-4 justify-evenly">
          {user.data?.membership_is_active ? (
            <>
              <Badge>Membership: {user.data?.plan.toLocaleUpperCase()}</Badge>
              <Badge>
                {formatDateMMDDYYYY(user.data?.plan_start_at)} - {formatDateMMDDYYYY(user.data?.plan_end_at)}
              </Badge>
            </>
          ) : (
            <>
              <Badge variant={'destructive'}>Membership Inactive</Badge>
            </>
          )}
          {user.data?.medical_provider ? (
            <>
              <Badge variant={'in_stock'}>Medical Provider</Badge>
            </>
          ) : (
            <>
              <Badge variant={'destructive'}>NON Medical Provider</Badge>
            </>
          )}
        </div>
      </div>

      <div className="flex gap-8">
        <div className="flex-1">
          <Section title={<>Requests({user.data.requests?.length})</>}>
            <div className="overflow-hidden overflow-y-scroll max-h-96">
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-200">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      ID
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Calls
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Meds
                    </th>

                    <th scope="col" className="px-6 py-3">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Address
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Created
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orderedRequests.map((request) => (
                    <tr key={request.id} className="text-gray-900 bg-white border-b">
                      <td scope="row" className="px-6 py-4 font-medium whitespace-nowrap">
                        <Link to={`/admin/requests/${request.id}`} className="hover:underline">
                          {request.id}
                        </Link>
                      </td>
                      <td className="px-6 py-4">{request.pharmacies?.length}</td>
                      <td className="px-6 py-4">
                        {request.medications?.map((medication) => (
                          <>
                            {medication?.medication} - {medication?.variant} - {medication?.dosage} -{' '}
                            {medication?.quantity}
                          </>
                        ))}
                      </td>
                      <td className="px-6 py-4">{request.status}</td>
                      <td className="px-6 py-4">{request.location?.address}</td>
                      <td className="px-6 py-4">
                        <Time>{request.created_at}</Time>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Section>
        </div>
        <div className="flex-1">
          <Section title={<>Medications({user.data.medications?.length})</>}>
            <div className="overflow-hidden overflow-y-scroll max-h-96">
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-200">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Medication
                    </th>
                    <th className="px-6 py-3">Variant</th>
                    <th className="px-6 py-3">QTY</th>
                    <th className="px-6 py-3">Dosage</th>
                    <th className="px-6 py-3">Name Brand/Generic</th>
                    <th className="px-6 py-3">Partial</th>
                    <th className="px-6 py-3">Note</th>
                    <th className="px-6 py-3">Created</th>
                  </tr>
                </thead>
                <tbody>
                  {user.data.medications?.map((medication) => (
                    <tr key={medication.id} className="text-gray-900 bg-white border-b">
                      <td scope="row" className="px-6 py-4 font-medium whitespace-nowrap">
                        <Link to={`/medications/${medication.id}`} className="block hover:underline">
                          {medication.medication}
                        </Link>
                      </td>
                      <td className="px-6 py-4">{medication.variant}</td>
                      <td className="px-6 py-4">{medication.quantity}</td>
                      <td className="px-6 py-4">
                        {medication.dosage} {medication.dosage_form}
                      </td>
                      <td className="px-6 py-4">{medication.preference}</td>
                      <td className="px-6 py-4">{medication.partial_stock_allowed ? 'Yes' : 'No'}</td>
                      <td className="px-6 py-4">{medication.note}</td>
                      <td className="px-6 py-4">
                        <Time>{medication.created_at}</Time>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Section>
        </div>
      </div>

      <div className="flex gap-8 mt-8">
        <div className="flex-1">
          <Section title={<>Locations ({user.data?.locations?.length})</>}>
            <div className="w-full overflow-hidden overflow-y-scroll max-h-96">
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-200">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Address
                    </th>
                    <th scope="col" className="px-6 py-3">
                      City
                    </th>
                    <th scope="col" className="px-6 py-3">
                      State
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Zip
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {user.data?.locations?.map((location) => (
                    <tr key={location.id} className="text-gray-900 bg-white border-b">
                      <td className="px-6 py-4">{location.address}</td>
                      <td className="px-6 py-4">{location.city}</td>
                      <td className="px-6 py-4">{location.state}</td>
                      <td className="px-6 py-4">{location.zip}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Section>
        </div>
        <div className="flex-1">
          <Section
            title={
              <div className="flex items-center justify-between w-full">
                <span>Credit Transactions ({user.data?.credit_transactions?.length})</span>
                <Button
                  onClick={() => setIsCreditDebitToolOpen(true)}
                  className="px-3 py-1 mx-4 text-purple-500 transition-colors duration-200 bg-transparent border border-purple-500 hover:text-purple-600 hover:border-purple-600"
                >
                  +/-
                </Button>
                <Badge>Credit Balance: {USDollar.format(user.data?.total_credits)}</Badge>
              </div>
            }
          >
            <div className="w-full overflow-hidden overflow-y-scroll max-h-96">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-200">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Date
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Amount
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Type
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Note
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Details
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedCreditTransactions.map((credit_transaction) => (
                    <tr key={credit_transaction.id} className="text-gray-900 bg-white border-b">
                      <td className="px-6 py-4">
                        <Time>{credit_transaction.created_at}</Time>
                      </td>
                      <td className="px-6 py-4">{USDollar.format(credit_transaction.amount)}</td>
                      <td className="px-6 py-4">{credit_transaction.transaction_type}</td>
                      <td className="px-6 py-4">
                        {credit_transaction.note.includes('Request:') ? (
                          <Link
                            to={`/admin/requests/${credit_transaction.note.match(/Request: (\w+-\w+-\w+-\w+-\w+)/)?.[1]}`}
                            className="text-blue-500 hover:underline"
                          >
                            {credit_transaction.note}
                          </Link>
                        ) : (
                          credit_transaction.note
                        )}
                      </td>
                      <td className="px-6 py-4">{credit_transaction.description}</td> {/* Display issuer's email */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Section>
        </div>
      </div>
      <div className="flex gap-8 mt-8">
        <div className="flex-1">
          <Section title={<>Latest Pharmacies ({user.data?.pharmacies?.length})</>}>
            <div className="w-full overflow-hidden overflow-y-scroll max-h-96">
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-200">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Phone
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Address
                    </th>
                    <th scope="col" className="px-6 py-3">
                      City
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {uniquePharmacies?.map((pharmacy) => (
                    <tr key={pharmacy.id} className="text-gray-900 bg-white border-b">
                      <td className="px-6 py-4">{pharmacy.name}</td>
                      <td className="px-6 py-4">{formatPhoneNumber(pharmacy.phone)}</td>
                      <td className="px-6 py-4">{pharmacy.address1}</td>
                      <td className="px-6 py-4">{pharmacy.city}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Section>
        </div>
        <div className="flex-1">
          <Section title={<>Sessions ({user.data?.sessions?.length})</>}>
            <div className="w-full overflow-hidden overflow-y-scroll max-h-96">
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-200">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      IP Address
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Logged In
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {user.data?.sessions?.map((session) => (
                    <tr key={session.id} className="text-gray-900 bg-white border-b">
                      <td className="px-6 py-4">{session.ip_address}</td>
                      <td className="px-6 py-4">
                        <Time>{session.created_at}</Time>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Section>
        </div>
      </div>
      {isCreditDebitToolOpen && (
        <CreditDebitTool
          userId={id as string}
          onClose={() => {
            setIsCreditDebitToolOpen(false);
            user.refetch();
          }}
        />
      )}
    </>
  );
};

export default Profile;
