import { useQueryClient } from 'react-query';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import useEmployees from '@/hooks/useEmployees';
import { ITask } from '@/types';
import axiosClient from '@/utils/axiosClient.ts';

interface TaskAssigmentProps {
  task: ITask;
}

const TaskAssigment = ({ task }: TaskAssigmentProps) => {
  const queryClient = useQueryClient();
  const employees = useEmployees();

  if (!task?.id) {
    return null;
  }

  const sortedEmployees =
    employees?.data?.sort((a, b) => {
      const nameA = a.user.name || '';
      const nameB = b.user.name || '';
      return nameA.localeCompare(nameB, undefined, { sensitivity: 'base' });
    }) || [];

  const save = (assigned_user_id: string) => {
    axiosClient
      .put(`/admin/tasks/${task.id}`, {
        assigned_user_id: assigned_user_id || null,
      })
      .then((response) => {
        task = response.data;

        queryClient.setQueryData([`admin/manual_calls`], (oldData: ITask[] | undefined) => {
          if (!oldData) return [task];
          return oldData.map((oldTask) => {
            if (oldTask.id === task.id) {
              return task;
            }
            return oldTask;
          });
        });
      });
  };

  return (
    <div>
      <Select onValueChange={save} value={task.assigned_user_id || ''}>
        <SelectTrigger>
          <SelectValue placeholder={`Assign To`} />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value={''}>Unassign</SelectItem>
          {sortedEmployees.map((employee) => (
            <SelectItem key={employee.user.id} value={employee.user.id}>
              {employee.user.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export default TaskAssigment;
