import { useState, useEffect } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { ArrowLeft, Infinity } from 'lucide-react';
import { Link } from 'react-router-dom';
import { ExpeditedIndicator } from '@/components/Requests/DataTable/ExpeditedIndicator';
import Section from '@/components/Section';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import useManualCalls from '@/hooks/useManualCalls';
import useMe from '@/hooks/useMe';
import useTickets from '@/hooks/useTickets';
import { ITask, ITicket } from '@/types';
import { cn } from '@/utils/utils';
import TaskAssigment from './TaskAssigment';

const REFETCH_INTERVAL = 10000; // 10 seconds

const Tickets = () => {
  const currentUser = useMe();
  const [taskFilter, setTaskFilter] = useState<'my' | 'all' | 'unassigned' | 'admin'>('my');

  const taskModal = useModal('task-modal');
  const scheduledTickets = useTickets('scheduled', {
    refetchInterval: REFETCH_INTERVAL,
  });
  const manualCalls = useManualCalls({
    refetchInterval: REFETCH_INTERVAL,
  });

  const allCalls = manualCalls?.data as ITask[];
  const myCalls = manualCalls?.data?.filter((task) => task.assigned_user_id === currentUser?.data?.id) as ITask[];
  const unassignedCalls = manualCalls?.data?.filter((task) => !task.assigned_user_id) as ITask[];

  const countAllTasks = allCalls?.length || 0;
  const countMyTasks = myCalls?.length || 0;
  const countUnassignedTasks = unassignedCalls?.length || 0;

  const getProcessingTimePriority = (task: ITask) => {
    const processingTime = task.request_ticket?.request?.processing_time;
    if (processingTime === 'Same Day Expedited' || processingTime === 'Weekend Expedited') {
      return 0; // Highest priority
    } else {
      return 1; // Lower priority
    }
  };

  const sortedCallsAll = allCalls?.sort((a, b) => {
    const priorityA = getProcessingTimePriority(a);
    const priorityB = getProcessingTimePriority(b);

    if (priorityA !== priorityB) {
      return priorityA - priorityB;
    } else {
      return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
    }
  });

  const sortedCallsMy = myCalls?.sort((a, b) => {
    const priorityA = getProcessingTimePriority(a);
    const priorityB = getProcessingTimePriority(b);

    if (priorityA !== priorityB) {
      return priorityA - priorityB;
    } else {
      return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
    }
  });

  const sortedCallsUnassigned = unassignedCalls?.sort((a, b) => {
    const priorityA = getProcessingTimePriority(a);
    const priorityB = getProcessingTimePriority(b);

    if (priorityA !== priorityB) {
      return priorityA - priorityB;
    } else {
      return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
    }
  });

  const calculateCompletionRatio = (ticket: ITicket) => {
    if (!ticket.tasks || ticket.tasks.length === 0) return '0/0';
    const completedTasks = ticket.tasks.filter((task) => task.status === 'completed').length;
    return `${completedTasks}/${ticket.tasks.length}`;
  };

  const calculateScheduledTickets = (tickets: ITicket[]) => {
    return tickets?.length || 0;
  };

  const adminEmails = ['lindsey@findneedle.co', 'dillon@findneedle.co', 'brett@findneedle.co', 'brennan@findneedle.co'];
  const isAdminUser = adminEmails.includes(currentUser?.data?.email);

  const availableTabs = isAdminUser ? ['my', 'all', 'unassigned', 'admin'] : ['my', 'all', 'unassigned'];

  useEffect(() => {
    if (!availableTabs.includes(taskFilter)) {
      setTaskFilter('my');
    }
  }, [taskFilter, availableTabs]);

  return (
    <div className="">
      <div className="flex justify-between w-full">
        <div className="flex items-center justify-start gap-1 mt-4 mb-6 sm:mt-2 ">
          <div className="mr-2 leading-snug text-gray-600 hover:text-violet-600">
            <Link to="/admin/tickets">
              <ArrowLeft />
            </Link>
          </div>
          <div className="font-medium leading-snug text-gray-400 hover:text-violet-600">
            <Link to="/admin/tickets">Work Items</Link>
          </div>
          <div className="px-2 font-medium leading-snug text-gray-400">/</div>
          <div className="font-medium leading-snug text-violet-600">Open Tickets</div>
        </div>
      </div>

      <div className="flex flex-col w-full gap-8 lg:flex-row">
        <div className="">
          <Section title={`Tickets (${calculateScheduledTickets(scheduledTickets?.data)})`}>
            <Table className="text-xs">
              <TableHeader>
                <TableRow>
                  <TableHead>Scheduled</TableHead>
                  <TableHead>Request</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead>Location</TableHead>
                  <TableHead>Completed</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {scheduledTickets?.data?.map((ticket: ITicket) => (
                  <TableRow key={ticket.id}>
                    <TableCell>
                      <div className="flex items-center space-x-2">
                        <ExpeditedIndicator processingTime={ticket.request?.processing_time} />
                        <span>{ticket.scheduled_at}</span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`/admin/requests/${ticket.request_id}`}
                        target="_blank"
                        className="underline underline-offset-2 decoration-gray-300 hover:decoration-gray-600"
                      >
                        {ticket.request_id}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`/admin/users/${ticket.user.id}`}
                        target="_blank"
                        className="flex items-center gap-2 underline underline-offset-2 decoration-gray-300 hover:decoration-gray-600"
                      >
                        {ticket.user.email}
                        {ticket.user.plan === 'unlimited' && <Infinity className="text-brand-purple" />}
                      </Link>
                    </TableCell>
                    <TableCell>{ticket.location}</TableCell>
                    <TableCell>{calculateCompletionRatio(ticket)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Section>
        </div>

        <div className="">
          <Section>
            <Tabs
              value={taskFilter}
              onValueChange={(value) => setTaskFilter(value as 'my' | 'all' | 'unassigned' | 'admin')}
            >
              <TabsList>
                <TabsTrigger value="my">My Calls ({countMyTasks})</TabsTrigger>
                <TabsTrigger value="all">All Calls ({countAllTasks})</TabsTrigger>
                <TabsTrigger value="unassigned">Unassigned Calls ({countUnassignedTasks})</TabsTrigger>
                {isAdminUser && <TabsTrigger value="admin">Admin</TabsTrigger>}
              </TabsList>
              <TabsContent value="my">
                <Table className="mt-4 text-xs">
                  <TableHeader>
                    <TableRow>
                      <TableHead className="w-1/5">Pharmacy</TableHead>
                      <TableHead className="w-1/5">Medication</TableHead>
                      <TableHead className="w-1/5">Dosage</TableHead>
                      <TableHead className="w-1/5">Quantity</TableHead>
                      <TableHead className="w-1/5">State</TableHead>
                      <TableHead className="w-1/5">Assignment</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {sortedCallsMy?.map((task: ITask) => (
                      <TableRow
                        key={task.id}
                        onClick={() => {
                          taskModal.show({
                            taskId: task.id,
                            requestId: task.request_ticket?.request?.id,
                          });

                          window.history.pushState(
                            null,
                            '',
                            `/admin/requests/${task.request_ticket?.request?.id}/${task.id}/${task.medication.id}`,
                          );
                        }}
                        className={cn(
                          'cursor-pointer',
                          task.assigned_user_id === currentUser?.data?.id ? 'bg-blue-100' : 'bg-white',
                        )}
                      >
                        <TableCell className="whitespace-normal">
                          <div className="flex items-center gap-2 space-x-2">
                            <ExpeditedIndicator processingTime={task.request_ticket?.request?.processing_time} />
                            <span>{task.pharmacy?.name || 'N/A'}</span>
                          </div>
                        </TableCell>
                        <TableCell className="whitespace-normal">{task.medication?.medication || 'N/A'}</TableCell>
                        <TableCell className="whitespace-normal">{task.medication?.dosage || 'N/A'}</TableCell>
                        <TableCell className="whitespace-normal">{task.medication?.quantity || 'N/A'}</TableCell>
                        <TableCell className="whitespace-normal">
                          {task.pharmacy.city}, {task.pharmacy.state}
                        </TableCell>
                        <TableCell className="whitespace-normal">
                          <TaskAssigment task={task} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TabsContent>
              <TabsContent value="all">
                <Table className="mt-4 text-xs">
                  <TableHeader>
                    <TableRow>
                      <TableHead className="w-1/5">Pharmacy</TableHead>
                      <TableHead className="w-1/5">Medication</TableHead>
                      <TableHead className="w-1/5">Dosage</TableHead>
                      <TableHead className="w-1/5">Quantity</TableHead>
                      <TableHead className="w-1/5">State</TableHead>
                      <TableHead className="w-1/5">Assignment</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {sortedCallsAll?.map((task: ITask) => (
                      <TableRow
                        key={task.id}
                        onClick={() => {
                          taskModal.show({
                            taskId: task.id,
                            requestId: task.request_ticket?.request?.id,
                          });

                          window.history.pushState(
                            null,
                            '',
                            `/admin/requests/${task.request_ticket?.request?.id}/${task.id}/${task.medication.id}`,
                          );
                        }}
                        className={cn(
                          'cursor-pointer',
                          task.assigned_user_id && task.assigned_user_id !== currentUser?.data?.id && 'bg-purple-100',
                          task.assigned_user_id === currentUser?.data?.id && 'bg-blue-100',
                          !task.assigned_user_id && 'bg-yellow-100',
                        )}
                      >
                        <TableCell className="whitespace-normal">
                          <div className="flex items-center gap-2 space-x-2">
                            <ExpeditedIndicator processingTime={task.request_ticket?.request?.processing_time} />
                            <span>{task.pharmacy?.name || 'N/A'}</span>
                          </div>
                        </TableCell>
                        <TableCell className="whitespace-normal">{task.medication?.medication || 'N/A'}</TableCell>
                        <TableCell className="whitespace-normal">{task.medication?.dosage || 'N/A'}</TableCell>
                        <TableCell className="whitespace-normal">{task.medication?.quantity || 'N/A'}</TableCell>
                        <TableCell className="whitespace-normal">
                          {task.pharmacy.city}, {task.pharmacy.state}
                        </TableCell>

                        <TableCell className="whitespace-normal">
                          <TaskAssigment task={task} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TabsContent>
              <TabsContent value="unassigned">
                <Table className="mt-4 text-xs">
                  <TableHeader>
                    <TableRow>
                      <TableHead className="w-1/5">Pharmacy</TableHead>
                      <TableHead className="w-1/5">Medication</TableHead>
                      <TableHead className="w-1/5">Dosage</TableHead>
                      <TableHead className="w-1/5">Quantity</TableHead>
                      <TableHead className="w-1/5">State</TableHead>
                      <TableHead className="w-1/5">Assignment</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {sortedCallsUnassigned?.map((task: ITask) => (
                      <TableRow
                        key={task.id}
                        onClick={() => {
                          taskModal.show({
                            taskId: task.id,
                            requestId: task.request_ticket?.request?.id,
                          });

                          window.history.pushState(
                            null,
                            '',
                            `/admin/requests/${task.request_ticket?.request?.id}/${task.id}/${task.medication.id}`,
                          );
                        }}
                        className={cn('cursor-pointer', !task.assigned_user_id && 'bg-yellow-100')}
                      >
                        <TableCell className="whitespace-normal">
                          <div className="flex items-center gap-2 space-x-2">
                            <ExpeditedIndicator processingTime={task.request_ticket?.request?.processing_time} />
                            <span>{task.pharmacy?.name || 'N/A'}</span>
                          </div>
                        </TableCell>
                        <TableCell className="whitespace-normal">{task.medication?.medication || 'N/A'}</TableCell>
                        <TableCell className="whitespace-normal">{task.medication?.dosage || 'N/A'}</TableCell>
                        <TableCell className="whitespace-normal">{task.medication?.quantity || 'N/A'}</TableCell>
                        <TableCell className="whitespace-normal">
                          {task.pharmacy.city}, {task.pharmacy.state}
                        </TableCell>
                        <TableCell className="whitespace-normal">
                          <TaskAssigment task={task} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TabsContent>

              {isAdminUser && (
                <TabsContent value="admin">
                  <div className="mt-4">
                    <iframe
                      src="https://usudillon.retool.com/apps/In-Progress%20Calls%20Dashboard"
                      width="100%"
                      height="800px"
                      frameBorder="0"
                    ></iframe>
                  </div>
                </TabsContent>
              )}
            </Tabs>
          </Section>
        </div>
      </div>
    </div>
  );
};

export default Tickets;
