import { ITextGroup } from '@/types.ts';
import { getUrl } from '@/utils/api';

function useTextGroups() {
  const query = getUrl('v2/text_messages', {
    refetchInterval: 5000,
  });
  return {
    ...query,
    data: query.data as ITextGroup[],
  };
}

export default useTextGroups;
