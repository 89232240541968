import { useParams } from 'react-router';
import { IAccountPatient } from '@/types.ts';
import { getUrl } from '@/utils/api';

function useAccountPatients() {
  const { accountId } = useParams();
  const query = getUrl(`admin/patients?account_id=${accountId}`);
  return {
    ...query,
    data: query.data as IAccountPatient[],
  };
}

export default useAccountPatients;
